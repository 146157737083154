import React, { useEffect } from "react";

import { Trans, useTranslation } from "react-i18next";

import girl from '../img/ContactUs/girl.png'

import StartTradeSwiper from '../components/Home/StartTradeSwiper'

import { Link } from "react-router-dom"

function ContactUs () {
 
    useEffect(() => {
        window.scrollTo(0, 0);
      }, []);

    const { t } = useTranslation();
      
    return(
        <div className="pt-[100px]">
            <div className="flex flex-col xl:flex-row justify-between max-w-[1280px] mx-[20px] xl:mx-auto">
                <div className="xl:pt-[110px]">
                    <p className="text-[25px] xl:text-[35px] open-sans font-[700] text-[#2F5D52]"><Trans>{t('contact.1')}</Trans></p>
                    <p className="open-sans max-w-[525px] text-[14px] xl:text-[16px] text-[#232323] mt-[20px] xl:mt-[30px]">{t('contact.2')}</p>
                    <p className="open-sans max-w-[525px] text-[14px] xl:text-[16px] text-[#232323] mt-[15px]">{t('contact.3')}</p>
                    <Link to="https://user.millenniumtrading.org/signup"><div className="cursor-pointer button2 flex mt-[40px] xl:mt-[50px] !bg-[#232323]">
                        <p className="text-white open-sans text-[14px] xl:text-[16px] font-[700]">{t('main.2')}</p>
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                            <path d="M5 12H19" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                            <path d="M12 5L19 12L12 19" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                        </svg>
                    </div></Link>
                </div>
                <div className="mt-[40px] xl:mt-[80px]">
                    <img src={girl} alt="" />
                </div>
            </div>
            <StartTradeSwiper/>
        </div>
    );
}

export default ContactUs;