import React, { useState, useEffect } from "react";
import { Link, useLocation } from "react-router-dom"
import { useTranslation } from "react-i18next";
import Dropdown from 'react-dropdown';
import 'react-dropdown/style.css';
import i18n from "i18next";
import Cookies from "js-cookie";
import logo from "../img/logo.png";
import MobileMenu from "./MobileMenu";
import arrow from '../img/Vector.svg'

function Header() {

    const [isProductsOpen, setIsProductsOpen] = useState(false);

    const toggleProductsMenu = () => {
        setIsProductsOpen(!isProductsOpen);
    };

    const closeProductsMenu = () => {
        setIsProductsOpen(false);
    };

    const location = useLocation();

    const { t } = useTranslation();

    const languages = [
        { value: 'en', label: 'EN' },
        { value: 'ru', label: 'RU' },
        { value: 'es', label: 'ES' },
        { value: 'it', label: 'IT' },
        { value: 'pl', label: 'PL' },
        { value: 'ar', label: 'AR' },
        { value: 'cs', label: 'CS' },
        { value: 'hr', label: 'HR' },
    ]

    const storedLang = Cookies.get("i18next");
    const [currentLang, setCurrentLang] = useState(storedLang || 'en');

    useEffect(() => {
        if (storedLang && storedLang !== currentLang) {
            setCurrentLang(storedLang);
            i18n.changeLanguage(storedLang);
        }
        // eslint-disable-next-line
        
    }, [storedLang]);
    

    const handleChangeLang = (selectedLang) => {
        setCurrentLang(selectedLang.value);
        i18n.changeLanguage(selectedLang.value);
        Cookies.set("i18next", selectedLang.value);
    };

    return (
        <div>
            <div className="hidden fixed w-full h-[100px] bg-white z-[999] mx-auto xl:flex items-center  header">
                <Link to="/"><img src={logo} alt="" className="ml-[20px] w-[150px]"/></Link>
                <div className="ml-auto flex items-center gap-[50px] mr-[20px]">
                    <div className="flex cursor-pointer" onClick={toggleProductsMenu}>
                        <p className="text-[16px] open-sans text-[#232323]">{t('header.1')}</p>
                        <img src={arrow} alt="" className={`transition-all ${isProductsOpen ? "ml-[6px]" : "ml-[6px] -rotate-180"}`} />
                        {isProductsOpen && (
                            <div className="absolute w-[347px] h-[155px] bg-[#EFECE7] rounded-[15px] top-[75px]">
                                <div className="flex justify-between mx-[30px] my-[20px]">
                                    <div className="flex flex-col gap-[15px]">
                                        <Link to="/markets"><p className={`text-[16px] open-sans ${location.pathname === '/markets' ? 'text-[#2F5D52] font-[500]' : 'text-[#424242]'}`}>{t('header.m.1')}</p></Link>
                                        <Link to="/cfd"><p className={`text-[16px] open-sans ${location.pathname === '/cfd' ? 'text-[#2F5D52] font-[500]' : 'text-[#424242]'}`}>{t('header.m.2')}</p></Link>
                                        <Link to="/crypto"><p className={`text-[16px] open-sans ${location.pathname === '/crypto' ? 'text-[#2F5D52] font-[500]' : 'text-[#424242]'}`}>{t('header.m.crypto')}</p></Link>
                                    </div>
                                    <div className="flex flex-col gap-[15px]">
                                        <Link to="/oil"><p className={`text-[16px] open-sans ${location.pathname === '/oil' ? 'text-[#2F5D52] font-[500]' : 'text-[#424242]'}`}>{t('header.m.3')}</p></Link>
                                        <Link to="/goldmetals"><p className={`text-[16px] open-sans ${location.pathname === '/goldmetals' ? 'text-[#2F5D52] font-[500]' : 'text-[#424242]'}`}>{t('header.m.4')}</p></Link>
                                    </div>
                                </div>
                            </div>
                        )}
                </div>
                    <Link onClick={closeProductsMenu} to="/accounttypes"><p className="text-[16px] open-sans text-[#232323]">{t('header.3')}</p></Link>
                    <Link onClick={closeProductsMenu} to="/contactus"><p className="text-[16px] open-sans text-[#232323]">{t('header.4')}</p></Link>
                    <Link onClick={closeProductsMenu} to="/aboutus"><p className="text-[16px] open-sans text-[#232323]">{t('header.5')}</p></Link>
                    <Dropdown
                        onClick={closeProductsMenu} 
                        options={languages}
                        placeholder="EN"
                        onChange={handleChangeLang}
                        value={languages.find(lang => lang.value === currentLang)}
                    />
                    <Link to="https://user.millenniumtrading.org/signup" onClick={closeProductsMenu} >
                        <div className="button cursor-pointer">
                            <p className="font-[600] text-[17px] open-sans text-[#232323]">{t('header.6')}</p>
                        </div>
                    </Link>
                </div>
            </div>
            <div className="xl:hidden w-full flex fixed z-[999] h-[60px] items-center justify-between bg-white">
                <Link to="/"><img src={logo} alt="" className="ml-[20px] w-[80px]"/></Link>
                <div className="flex items-center gap-[10px]">
                    <Dropdown
                        onClick={closeProductsMenu} 
                        options={languages}
                        placeholder="EN"
                        onChange={handleChangeLang}
                        value={languages.find(lang => lang.value === currentLang)}
                    />
                    <MobileMenu className="mr-[20px]"/>
                </div>      
            </div>
        </div>
    );
}

export default Header;
