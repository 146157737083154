import React from "react";

import { Trans, useTranslation } from "react-i18next";

import { Link } from "react-router-dom"

function Start () {

    const { t } = useTranslation();
 
    return(
        <div className="mt-[80px] xl:mt-[200px] relative overflow-hidden">
            <div className="max-w-[1280px] relative xl:mx-auto mx-[20px] flex flex-col xl:flex-row justify-between">
                <div className="xl:max-w-[525px]">
                    <p className="roboto text-[#2F5D52] font-[700] text-[25px] xl:text-[40px]">{t('start.1')}</p>
                    <p className="mt-[20px] xl:mt-[30px] open-sans text-[#232323] text-[14px] xl:text-[16px]"><Trans>{t('start.2')}</Trans></p>
                    <Link to="https://user.millenniumtrading.org/signup"><div className="cursor-pointer button2 flex mt-[20px] xl:mt-[40px]">
                        <p className="text-white open-sans text-[14px] xl:text-[16px] font-[700]">{t('main.2')}</p>
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                            <path d="M5 12H19" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                            <path d="M12 5L19 12L12 19" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                        </svg>
                    </div></Link>
                </div>
                <div className="grid grid-cols-1 md:grid-cols-2 mt-[30px] xl:mt-[100px] xl:mb-[80px] gap-y-[10px] xl:gap-y-0 gap-x-[50px]">
                    <p className="text-[#232323] open-sans text-[16px]">{t('start.5')}</p>
                    <p className="text-[#232323] open-sans text-[16px]">{t('start.6')}</p>
                    <p className="text-[#232323] open-sans text-[16px]">{t('start.7')}</p>
                    <p className="text-[#232323] open-sans text-[16px]">{t('start.8')}</p>
                </div>
            </div>
        </div>
    );
}

export default Start;