import React from "react";

import { useTranslation } from "react-i18next";

import { Link } from "react-router-dom"

function VisionMision () {

    const { t } = useTranslation();
 
    return(
        <div className="xl:pt-[200px] relative max-w-[1280px] mx-[20px] xl:mx-auto">
            <div className="flex flex-col xl:flex-row justify-between">
                <div className="xl:w-[500px] h-[293px] bg-[#232323] rounded-[50px] flex flex-col items-center justify-center">
                    <p className="text-white open-sans text-[20px] xl:text-[30px] font-[600]">{t('aboutus.5')}</p>
                    <p className="text-white open-sans text-[14px] xl:text-[16px] font-[400] mt-[30px] text-center max-w-[400px] mx-[20px] xl:mx-0">{t('aboutus.6')}</p>
                    <Link to="https://user.millenniumtrading.org/signup">
                    <div className="cursor-pointer button2 !bg-[#FFF] flex mt-[40px] xl:mt-[50px]">
                        <p className="text-black open-sans text-[14px] xl:text-[16px] font-[700]">{t('main.2')}</p>
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                            <path d="M5 12H19" stroke="black" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                            <path d="M12 5L19 12L12 19" stroke="black" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                        </svg>
                    </div>
                    </Link>
                </div>
                <div className="xl:w-[500px] h-[293px] bg-[#FB6640] rounded-[50px] flex flex-col items-center justify-center mt-[20px] xl:mt-0">
                    <p className="text-white open-sans text-[20px] xl:text-[30px] font-[600]">{t('aboutus.7')}</p>
                    <p className="text-white open-sans text-[14px] xl:text-[16px] font-[400] mt-[30px] text-center max-w-[400px] mx-[20px] xl:mx-0">{t('aboutus.8')}</p>
                    <Link to="https://user.millenniumtrading.org/signup"><div className="cursor-pointer button2 !bg-[#FFF] flex mt-[40px] xl:mt-[50px]">
                        <p className="text-black open-sans text-[14px] xl:text-[16px] font-[700]">{t('main.2')}</p>
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                            <path d="M5 12H19" stroke="black" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                            <path d="M12 5L19 12L12 19" stroke="black" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                        </svg>
                    </div>
                    </Link>
                </div>
            </div>
        </div>
    );
}

export default VisionMision;